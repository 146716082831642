import { Link } from "gatsby-plugin-react-i18next";
import "./ubicacion.scss";

import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Subrayado2 from "../../svg/subrayados/subrayado02.svg";
import Subrayado3 from "../../svg/subrayados/subrayado03.svg";
import Subrayado4 from "../../svg/subrayados/subrayado04.svg";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Ubicacion({ restaurant, visibility }) {
	const { t } = useTranslation();
	// console.log(visibility);
	return (
		<div className={`ubicacion ${visibility}`}>
			<div className="images">
				<GatsbyImage
					image={restaurant.images[0].childImageSharp.gatsbyImageData}
					class="ubicacionImg"
					alt=""
				/>
			</div>

			<div className="content">
				<h2>{restaurant.title}</h2>

				<h3>
					<Trans>Cómo llegar</Trans>
				</h3>
				<a
					href={restaurant.addressLink}
					target="_blank"
					rel="noopener noreferrer"
				>
					{restaurant.address1 +
						", " +
						restaurant.addressPostal +
						" " +
						restaurant.addressCity}
				</a>

				<h3>
					<Trans>Horarios</Trans>
				</h3>
				<div className="hours">
					{restaurant.hours.miniBrunch && (
						<div>
							<div>
								<p>
									<Trans>Minibrunch</Trans>
								</p>
							</div>

							<div>
								{restaurant.hours.miniBrunch.map((hoursLine, i) => (
									<p key={"miniBrunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{restaurant.hours.brunch && (
						<div>
							<div>
								<p>
									<Trans>Brunch</Trans>
								</p>
							</div>

							<div>
								{restaurant.hours.brunch.map((hoursLine, i) => (
									<p key={"brunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{restaurant.hours.lunch && (
						<div>
							<div>
								<p>
									<Trans>Comida</Trans>
								</p>
							</div>

							<div>
								{restaurant.hours.lunch.map((hoursLine, i) => (
									<p key={"lunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{restaurant.hours.afternoon && (
						<div>
							<div>
								<p>
									<Trans>Tarde</Trans>
								</p>
							</div>

							<div>
								{restaurant.hours.afternoon.map((hoursLine, i) => (
									<p key={"lunch" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
					{restaurant.hours.dinner && (
						<div>
							<div>
								<p>
									<Trans>Cena</Trans>
								</p>
							</div>

							<div>
								{restaurant.hours.dinner.map((hoursLine, i) => (
									<p key={"dinner" + i}>{hoursLine} h.</p>
								))}
							</div>
						</div>
					)}
				</div>

				<div className="links">
					<Link to={"/reserva?pre=" + restaurant.id}>
						<h3>
							<Trans>Reserva</Trans>
							<Subrayado2 />
						</h3>
					</Link>
					{restaurant.takeAwayLink && (
						<a
							href={restaurant.takeAwayLink}
							target="_blank"
							rel="noopener noreferrer"
							aria-label={t("Recoge en local")}
						>
							<h3>
								<Trans>Recoge en local</Trans>
								<Subrayado4 />
							</h3>
						</a>
					)}

					{restaurant.deliveryLinks && (
						<a
							href="https://linktr.ee/beginrestaurante"
							target="_blank"
							rel="noopener noreferrer"
							aria-label={t("A domicilio")}
						>
							<h3>
								<Trans>A domicilio</Trans>
							</h3>
							<Subrayado3 />
						</a>
					)}
				</div>
			</div>
		</div>
	);
}
